<template>
  <ion-page>
    <ion-header class="headerTop ion-no-border">
      <headertoolbar></headertoolbar>
    </ion-header>
    <ion-content :fullscreen="true" scrollEvents={true}>
      <div class="original_main_container">
        <ion-header collapse="condense">

        </ion-header>
        <ion-icon class="l_close_a" button @click="this.$router.push('/tabs/tabReproductor')"
          src="assets/ICONOS/close.svg"></ion-icon>
        <p class="tabTitle">Categorías musicales</p>
        <ion-text class="title_desc" v-if="hayCategorias == 'si'">
          <p>Seleccione una categoría musical para reproducir:</p>
        </ion-text>
        <ion-text class="title_desc" v-else-if="hayCategorias == 'no' && categoriasReq.length < 2">
          <h5>Sin categorías disponibles...</h5>
          <p>Por el momento usted no cuenta con categorías musicales disponibles,
            si desea agregarlas, favor de ponerse en contacto
            con alguno de nuestros asesores.
          </p>
          <footerContactanos></footerContactanos>
        </ion-text>
        <div class="out_loader" v-if="categoriasReq.length == 0">
          <div class="loader"></div>
        </div>
        <ion-grid class="cat_grid" v-else-if="categoriasReq.length >= 2">
          <ion-row>
            <ion-col size="12">
            </ion-col>
            <ion-col size="6" v-for="(categoria, index) in categoriasReq" :key="categoria.id" class="catCard"
              v-on:click="confirmarCambioCategoria(categoria.id)"
              :class="{ 'activeCategory': currentCategoria == categoria.id }">
              <span :class="'back back_color_' + color_cat[index] + ''"></span>
              <span class="catCardTitle">{{ categoria.name }}</span>
              <span class="Cat_base" :class="categoria.name == 'Base' ? 'Cat_base_s' : 'Cat_base_n'"
                :style="'background-color: #' + ddata.color + ';'"></span>
              <ion-img v-if="categoria.name != 'Base'"
                :src="categoria.playlist_id ? 'https://pub-3b7d9c13a5754ebeaa0345eb87aa22c1.r2.dev/images/category/' + categoria.playlist_id + '.jpg' : 'https://pub-3b7d9c13a5754ebeaa0345eb87aa22c1.r2.dev/images/category/r' + getImgUrl() + '.jpg'"
                class="catCardImage"></ion-img>
              <ion-img v-else :src="'https://pub-3b7d9c13a5754ebeaa0345eb87aa22c1.r2.dev/images/' + ddata.mobileimage" class="catCardImageBase"></ion-img>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="js">
/* eslint-disable */
import { IonPage, IonHeader, IonToolbar, IonContent, IonCol, IonRow, IonGrid, alertController, menuController, loadingController } from '@ionic/vue';
import axios from 'axios';
import { defineComponent } from 'vue';
import { Storage } from '@capacitor/storage';
import footerContactanos from './footerContactanos.vue';
import headertoolbar from './header_toolbar.vue';
import { App } from '@capacitor/app';

export default defineComponent({
  name: 'Reproductor',
  components: { IonHeader, IonToolbar, IonContent, IonPage, IonCol, IonRow, IonGrid, headertoolbar, footerContactanos, },
  data() {
    return {
      clientData: {},
      categorias: [{
        id: 1,
        name: "Lista de Éxitos",
        imagePath: "lista-de-exitos.png"
      }],
      categoriasReq: [],
      currentCategoria: "",
      loadingTimeout: 20000,
      hayCategorias: "",
      clientImageSrc: "",
      ddata: [],
      color_cat: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
    }
  },
  methods: {
    async presentLoading() {
      const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Cargando...',
          duration: this.loadingTimeout,
        });
      await loading.present();
      setTimeout(function () {
        loading.dismiss()
      }, this.loadingTimeout);
    },
    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
    async loadClient() {
      try {
        this.categoriasReq = [];
        this.categoriasReq = this.ddata.music_variants;
        this.clientImageSrc = 'https://pub-3b7d9c13a5754ebeaa0345eb87aa22c1.r2.dev/images/' + this.ddata.mobileimage;
        console.log("ddata de loadClient de tabCategorias");
        // console.log(this.ddata);
        if (this.categoriasReq.length == 0) {
          this.categoriasReq.length > 0 ? this.hayCategorias = "si" : this.hayCategorias = "no";
          this.categoriasReq.unshift({ id: 0, name: "Base", playlist_id: null })
        } else {
          if (this.categoriasReq[0]['name'] != "Base") {
            this.categoriasReq.length > 0 ? this.hayCategorias = "si" : this.hayCategorias = "no";
            this.categoriasReq.unshift({ id: 0, name: "Base", playlist_id: null })
          }
        }
        // console.log(this.categoriasReq);
        console.log('loadClient Cargado');
      } catch (e) {
        // this.presentAlertConfirm("Ha ocurrido un error en la función loadClient de categorias", e);
        console.log("Ha ocurrido un error en la función loadClient de categorias", e);
      }
    },
    getImgUrl() {
      // return require('~@/assets/categorias/'+pic);
      return (Math.floor(Math.random() * (8 - 1 + 1)) + 1);
    },
    async cambiarCategoria(idCategoria) {
      try {
        console.log(idCategoria);
        await Storage.set({
          key: "categoriaSeleccionada",
          value: JSON.stringify(idCategoria),
        });
        console.log("Categoría asignada a storage exitosamente - " + idCategoria);
        this.currentCategoria = idCategoria;
        await this.getClientData("categoriaSeleccionada");
      } catch (e) {
        this.presentAlertConfirm("Ha ocurrido un error en la función cambiarCategoria", e);
      }
    },
    async confirmarCambioCategoria(idCategoria) {
      try {
        const alert = await alertController
          .create({
            header: "Aviso",
            message: "¿Deseas cambiar a esta categoría musical?",
            buttons: [
              {
                text: 'Si',
                handler: () => {
                  console.log('Cambiar categoría pulsado, nueva categoría id: ' + idCategoria);
                  // this.emitter.emit("rep-func-kill", "Matando Reproductores desde tabCategorias");
                  this.cambiarCategoria(idCategoria);
                  // this.$router.go("/tabs/tabReproductor");
                }
              },
              {
                text: 'Cancelar',
                handler: () => {
                  console.log('Cancelar pulsado');
                }
              },
            ]
          });
        if (idCategoria != this.currentCategoria) {
          return alert.present();
        } else {
          return null;
        }
      } catch (e) {
        this.presentAlertConfirm("Ha ocurrido un error en la función confirmarCambioCategoria", e);
      }
    },
    async getClientData(keyName) {
      try {
        const ret = await Storage.get({ key: keyName });
        if (ret.value) {
          this.currentSucursal = this.isJson(ret.value);
          console.log("Recuperado de storage, key:" + keyName + " - " + this.currentSucursal);
        } else {
          console.log("No se pudo recuperar de storage, key:" + keyName + " - " + this.currentSucursal);
        }
      } catch (e) {
        this.presentAlertConfirm("Ha ocurrido un error en la función getClientData", e);
      }
    },

    async getFullClientData() {
      try {
        const ret = await Storage.get({ key: "clientData" });
        if (ret.value) {
          this.clientData = JSON.parse(ret.value);
          console.log("Recuperado de storage, key: clientData - " + this.clientData);

          if (this.clientData.sonara) {
            this.emitter.emit("sonara", true);
          } else {
            this.emitter.emit("sonara", false);
          }
        } else {
          console.log("No se pudo recuperar de storage, key: clientData - " + this.clientData);
        }
      } catch (e) {
        this.presentAlertConfirm("Ha ocurrido un error en la función getFullClientData", e);
      }
    },
    isJson(str) {
      try {
        const parsedStr = JSON.parse(str);
        return parsedStr;
      } catch (e) {
        return str;
      }
    },
    async getCategoria() {
      try {
        const retCategoria = await Storage.get({ key: "categoriaSeleccionada" });
        if (retCategoria.value) {
          this.currentCategoria = JSON.parse(retCategoria.value);
          console.log("Categoría consultada: " + retCategoria.value);
        }
      } catch (e) {
        this.presentAlertConfirm("Ha ocurrido un error en la función getCategoria", e);
      }
    },
    async presentAlertConfirm(headerAlert, messageAlert) {
      const alert = await alertController
        .create({
          header: headerAlert,
          message: messageAlert,
          buttons: ['Ok'],
        });
      return alert.present();
    },
  },
  mounted: function () {
    try {
      this.emitter.on("g-data", ddata => {
        this.ddata = ddata;
        // console.log(this.ddata);
        setTimeout(() => {
          this.loadClient();
          this.getCategoria();
        }, 1000);
      });
      this.emitter.on("cambio-categoria", idCategoria => {
        this.currentCategoria = idCategoria;
      });

      // sacar clientdata del storage y si no existe, hacer la consulta
      this.getFullClientData();

    } catch (e) {
      this.presentAlertConfirm("Ha ocurrido un error en la función mounted de tabCategorias", e);
    }


  },
  created() {
    App.addListener('backButton', ({ canGoBack }) => {
      if (!canGoBack) {
        console.log('Cant go back')
        this.$router.push('/tabs/tabReproductor')
      } else {
        console.log('Can go back')
      }
    });
  }
})
</script>


<style scoped>
* {
  font-family: 'Montserrat', sans-serif;
}

.headerTop {
  background-color: black;
}

/* .catCard{
  position: relative;
  text-align: center;
}
.catCardTitle {
  font-weight: bold;
  z-index: 1;
  position: absolute;
}

.catCardImage{
  position: absolute;
  left:0;
  top:0;
  z-index: 0;
  width: 80%;
} */

.catCard {
  border-radius: 10px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  float: left;
  width: calc(50% - 10px) !important;
  max-width: calc(50% - 10px) !important;
  overflow: hidden;
  border-style: solid;
  border-color: #000;
  border-width: 5px;
  cursor: pointer;
}

.activeCategory {
  border-color: white;
  cursor: default;
}

.catCard .back {
  position: absolute;
  width: calc(100% - 0px);
  /* z-index: 1; */
  height: calc(100% - 0px);
}


.catCard .back_color_1 {
  background-color: #00A8AD;
}

.catCard .back_color_2 {
  background-color: #7A3B3F;
}

.catCard .back_color_3 {
  background-color: #766089;
}

.catCard .back_color_4 {
  background-color: #E7C400;
}

.catCard .back_color_5 {
  background-color: #60A817;
}

.catCard .back_color_6 {
  background-color: #E41400;
}

.catCard .back_color_7 {
  background-color: #F96800;
}

.catCard .back_color_8 {
  background-color: #A900FF;
}

.catCard .back_color_9 {
  background-color: #1BA0E1;
}

.catCard .back_color_10 {
  background-color: #647686;
}

.catCard .back_color_11 {
  background-color: #6A00FF;
}

.catCard .back_color_12 {
  background-color: #EFA20A;
}

.catCard .back_color_13 {
  background-color: #A10025;
}

.catCard .back_color_14 {
  background-color: #A3C300;
}

.catCard .back_color_15 {
  background-color: #0050EE;
}

.catCard .back_color_16 {
  background-color: #815A2C;
}

.catCard .back_color_17 {
  background-color: #6D8664;
}

.catCard .back_color_18 {
  background-color: #008900;
}

.catCard .catCardImage {
  z-index: 0;
  width: 100px;
  margin-left: calc(100% - 100px);
  height: 100px;
  -webkit-mask-image: linear-gradient(to right, transparent 5%, #000 65%);
  mask-image: linear-gradient(to right, transparent 5%, #000 65%);
}

.catCard .catCardImageBase {
  z-index: 0;
  width: 100px;
  margin-left: calc(100% - 100px);
  height: 100px;
  -webkit-mask-image: linear-gradient(to right, transparent 0%, #000 20%);
  mask-image: linear-gradient(to right, transparent 0%, #000 20%);
}

.catCard .Cat_base {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  /*-webkit-mask-image: linear-gradient(to right, transparent 5%, #000 30%);
  mask-image: linear-gradient(to right, transparent 5%, #000 30%);*/
}

.catCard .Cat_base::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  width: 100px;
  background-color: RGBA(0, 0, 0, .3);
  -webkit-mask-image: linear-gradient(to left, transparent 2%, #000 100%);
  mask-image: linear-gradient(to left, transparent 2%, #000 100%);
}

.catCard .Cat_base_n {
  display: none;
}

.catCard .Cat_base_s {
  display: initial;
}

.catCard .catCardTitle {
  text-shadow: 0 0 4px black;
  vertical-align: middle;
  text-align: left;
  width: 90%;
  padding: 5%;
  position: absolute;
  z-index: 1;
  font-weight: bold;
  font-size: 18px;
}

.cat_grid {
  /*max-width: 600px;*/
}

@media only screen and (min-width: 500px) {
  .catCard .catCardTitle {
    font-size: 22px;
  }

  .catCard {
    height: 130px;
  }

  .catCard .catCardImage {
    height: 130px;
    width: 130px;
    margin-left: calc(100% - 130px);
  }

}

@media only screen and (min-width: 650px) {
  .catCard .catCardTitle {
    font-size: 20px;
  }

  .catCard {
    width: calc(33.33% - 10px) !important;
    max-width: calc(33.33% - 10px) !important;
  }
}

@media only screen and (min-width: 767px) {
  .catCard {
    width: calc(25% - 10px) !important;
    max-width: calc(25% - 10px) !important;
  }
}

@media only screen and (min-width: 960px) {
  .cat_grid {
    /*max-width: 900px;*/
  }

  .catCard {
    width: calc(20% - 10px) !important;
    max-width: calc(20% - 10px) !important;
  }
}

@media only screen and (min-width: 1120px) {
  .cat_grid {
    /*max-width: 1000px;*/
  }

}

@media only screen and (min-width: 1220px) {
  .cat_grid {
    /*max-width: 1120px;*/
  }

}


/* LOADER ----------------------------------*/
.out_loader {
  width: 60px;
  margin: 100px auto;
}

.loader {
  border: 10px solid #3c3c3c;
  border-radius: 50%;
  border-top: 10px solid #c3c3c3;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* LOADER ----------------------------------*/
</style>